import * as React from "react"
import { graphql } from "gatsby"
import { uniqueId } from "lodash"
import { Col, Row, PageHeader } from "antd"
import { StaticImage } from "gatsby-plugin-image"

import SiteLayout from "../components/site-layout"
import Seo from "../components/seo"
import { getContent } from "../utils/selectors"

const AboutPage = ({ location, data }) => {
  /**
   * VARS
   */
  const content = getContent(data)

  /**
   * RENDER
   */
  return (
    <SiteLayout location={location}>
      <Seo title="About" />
      <PageHeader className="content-page-header" title="About">
        <Row gutter={16}>
          <Col span={12}>
            {content.map(item => (
              <p
                dangerouslySetInnerHTML={{ __html: item.innerHtml }}
                key={uniqueId("p")}
              />
            ))}
          </Col>
          <Col span={12}>
            <StaticImage
              width={200}
              src="../images/rb.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Ryan Boros"
            />
          </Col>
        </Row>
      </PageHeader>
    </SiteLayout>
  )
}

/**
 * GRAPHQL
 */
export const query = graphql`
  query {
    allWpPage(filter: { slug: { eq: "about" } }) {
      edges {
        node {
          blocks {
            innerHtml
            tagName
          }
        }
      }
    }
  }
`

export default AboutPage
