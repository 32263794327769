import { first } from "lodash"

export const getProjects = data => {
  const {
    allWpProject: { edges },
  } = data

  return edges.map(edge => {
    return {
      ...edge?.node,
      tags: edge?.node?.terms?.nodes.map(item => item.slug),
    }
  })
}

export const getThumbnailPath = featuredImage => {
  const {
    node: {
      mediaDetails: { sizes },
    },
  } = featuredImage

  const thumbnails = sizes.map(size => size.sourceUrl)

  return thumbnails.find(thumb => thumb.includes("320x180"))
}

export const getContent = data => {
  const {
    allWpPage: { edges },
  } = data

  return first(edges).node.blocks
}
